import "./index.css";

interface PropsType {
    step: number | null;
    color: string;
}

export default function StepProgressBar({step, color}: PropsType): JSX.Element {
    const setStepItemStyle = (step: number | null, id: number, color: string) => {
        if (step === null) {
            return {
                backgroundColor: "#cdcdcd",
                border: "none",
            };
        }
        if (step === id) {
            return {
                backgroundColor: color,
                border: "none",
            };
        } else if (step > id) {
            return {
                backgroundColor: "#fff",
                border: `3px solid ${color}`,
            };
        } else {
            return {
                backgroundColor: "#cdcdcd",
                border: "none",
            };
        }
    };

    const setStepBarItemStyle = (step: number | null, id: number, color: string) => {
        if (step === null) {
            return {
                backgroundColor: "#cdcdcd",
            };
        }
        if (step >= id) {
            return {
                backgroundColor: color,
            };
        } else {
            return {
                backgroundColor: "#cdcdcd",
            };
        }
    };

    return (
        <div className="StepBarWrapper">
            <div className="StepItem" style={setStepItemStyle(step, 0, color)}></div>
            <div className="StepBarItem" style={setStepBarItemStyle(step, 1, color)}></div>
            <div className="StepItem" style={setStepItemStyle(step, 1, color)}></div>

            <div className="StepBarItem" style={setStepBarItemStyle(step, 2, color)}></div>
            <div className="StepItem" style={setStepItemStyle(step, 2, color)}></div>
            <div className="StepBarItem" style={setStepBarItemStyle(step, 3, color)}></div>

            <div className="StepItem" style={setStepItemStyle(step, 3, color)}></div>
            <div className="StepBarItem" style={setStepBarItemStyle(step, 4, color)}></div>
            <div className="StepItem" style={setStepItemStyle(step, 4, color)}></div>
        </div>
    );
}

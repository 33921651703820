import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import counterReducer from "../features/counter/counterSlice";
import addressReducer from "../features/address/addressSlice";
import businessReducer from "../features/address/businessSlice";
import weatherReducer from "../features/address/weatherSlice";
import aiDetectionReducer from "../features/address/aiDetectionSlice";
import climateRiskReducer from "../features/risk/climateRiskSlice";
import userAnnotationReducer from "../features/userAnnotation/userAnnotationSlice";
import alertMapReducer from "../features/alertMap/alertMapSlice";
import alertEditorReducer from "../features/alertEditorApi/alertEditorSlice";
import arretPerilReducer from "../features/arretPeril/arretPerilSlice";
import userInfoReducer from "../features/userInfo/userInfoSlice";
import sevesoReducer from "../features/seveso/sevesoSlice";
import RootSaga from "./rootSaga";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers({
  counter: counterReducer,
  address: addressReducer,
  business: businessReducer,
  ai: aiDetectionReducer,
  weather: weatherReducer,
  climateRisk: climateRiskReducer,
  userAnnotation: userAnnotationReducer,
  alertMap: alertMapReducer,
  alertEditor: alertEditorReducer,
  perils: arretPerilReducer,
  userInfo: userInfoReducer,
  sites_seveso: sevesoReducer,
});

const rootReducer = (state : any, action : any) => {
  if (action.type === "RESET_STATE") {
    state = undefined; // Reset the state
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(RootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from "react";
import _ from "lodash";
import moment from "moment";

import {useAppSelector, useAppDispatch} from "../../app/hooks";
import {useSnackbar} from "notistack";
import {fetchAlertMapAction, fetchAlertMapDayAction, selectAlertMap} from "../alertMap/alertMapSlice";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Checkbox from "@mui/material/Checkbox";
import {Backdrop, FormControlLabel, FormGroup, Switch, Theme} from "@mui/material";
import {alpha, styled} from "@mui/material/styles";
import {red, yellow} from "@mui/material/colors";
import {Modal} from "@mui/base/Modal";

import {AlertItemCheckBoxType} from "../../types";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import AlertAccordionList from "./AlertAccordionList";
import AlertItemCheckbox from "./AlertItemCheckbox";
import DatePickerRange from "./DatePickerRange";
import AlertEditor from "../../components/AlertEditor";
import "react-datepicker/dist/react-datepicker.css";

import "./D4Weather.css";
import AppBar from "../address/AppBar";

import useMapboxHandler from "./hooks/useMapboxHandler";

const drawerWidth = 350;

const isFeatureCollection: boolean = true;
const filterYellow: (feature: any) => boolean = (feature: any) => feature.properties.level === "JAUNE" || feature.properties.level === "Dégradé";
const filterOrange: (feature: any) => boolean = (feature: any) => feature.properties.level === "ORANGE" || feature.properties.level === "Mauvais";
const filterRed: (feature: any) => boolean = (feature: any) =>
    feature.properties.level === "ROUGE" || feature.properties.level === "Très mauvais" || feature.properties.level === "Extrêmement mauvais";

const RedSwitch = styled(Switch)(({theme}) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: red[600],
        "&:hover": {
            backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: red[600],
    },
}));

const YellowSwitch = styled(Switch)(({theme}) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: yellow[600],
        "&:hover": {
            backgroundColor: alpha(yellow[600], theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: yellow[600],
    },
}));

const StyledModal = styled(Modal)`
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 0.5);
    -webkit-tap-highlight-color: transparent;
    overflow-y: auto;
`;

const style = (theme: Theme) => ({
    width: 600,
    borderRadius: "12px",
    padding: "16px 32px 24px 32px",
    backgroundColor: "white",
    boxShadow: "0px 2px 24px #383838",
});

export default function D4Weather() {
    const dispatch = useAppDispatch();

    const alertMap = useAppSelector(selectAlertMap);
    const {enqueueSnackbar} = useSnackbar();
    const [data, setData] = useState<any>();

    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());

    const [isCheckedYellow, setIsCheckedYellow] = useState<boolean>(true);
    const [isCheckedOrange, setIsCheckedOrange] = useState<boolean>(true);
    const [isCheckedRed, setIsCheckedRed] = useState<boolean>(true);
    const [selectedFilter, setSelectedFilter] = useState<((feature: any) => boolean)[]>([filterYellow, filterOrange, filterRed]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const {mapRef, alert, listCheckBox, allChecked, initMapWeatherRisk, onLoadMapWeather, handleLayerAlert, setListCheckBox, setAllChecked} =
        useMapboxHandler();

    useEffect(() => {
        dispatch(fetchAlertMapAction(isFeatureCollection));
        initMapWeatherRisk(mapRef);
        if (mapRef.current) {
            onLoadMapWeather(mapRef);
        }
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (startDate.unix() !== endDate.unix()) {
            dispatch(fetchAlertMapDayAction(isFeatureCollection, startDate.utc().format("YYYY-MM-DD"), endDate.utc().format("YYYY-MM-DD")));
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (alertMap.error) {
            enqueueSnackbar("L'interrogation des alerts a échoué", {variant: "error"});
        }
        if (alertMap.value) {
            const filterData = {
                type: alertMap.value.type,
                features: [...alertMap.value.features],
            };
            filterData.features = filterData.features.filter((feature: any) => selectedFilter.some((filter) => filter(feature)));
            setData(filterData);
        }
    }, [alertMap, selectedFilter]);

    useEffect(() => {
        const newFilter = [
            isCheckedYellow ? filterYellow : undefined,
            isCheckedOrange ? filterOrange : undefined,
            isCheckedRed ? filterRed : undefined,
        ].filter(Boolean) as ((feature: any) => boolean)[];
        setSelectedFilter(newFilter);
    }, [isCheckedYellow, isCheckedRed, isCheckedOrange]);

    useEffect(() => {
        if (data && mapRef.current) {
            const alertSource: any = mapRef.current.getSource("alerts");
            if (alertSource) {
                alertSource.setData(data);
            }
        }
    }, [data]);

    useEffect(() => {
        setAllChecked(listCheckBox.every((item) => item.isShow === true));
    }, [listCheckBox]);

    const handleChangeDateRange = ({startDate, endDate}: {startDate: Date; endDate: Date}) => {
        setStartDate(moment(startDate));
        setEndDate(moment(startDate).add(2, "days"));
    };

    const handleAllCheckboxChange = () => {
        const toggledState = !allChecked;
        setAllChecked(toggledState);

        if (toggledState || (!toggledState && listCheckBox.every((item) => item.isShow))) {
            const updatedListCheckBoxAlert = listCheckBox.map((item) => ({
                ...item,
                isShow: toggledState,
            }));
            updatedListCheckBoxAlert.forEach((item) => {
                handleLayerAlert(item.id, toggledState);
            });
            setListCheckBox(updatedListCheckBoxAlert);
        }
    };

    const handleCheckboxYellow = () => {
        setIsCheckedYellow(!isCheckedYellow);
    };

    const handleCheckboxOrange = () => {
        setIsCheckedOrange(!isCheckedOrange);
    };

    const handleCheckboxRed = () => {
        setIsCheckedRed(!isCheckedRed);
    };

    const handleModalClose = () => setIsModalOpen(false);

    return (
        <div className="wrapperMap">
            <StyledModal open={isModalOpen} onClose={handleModalClose} slots={{backdrop: StyledBackdrop}}>
                <Box sx={style}>
                    <AlertEditor onClose={handleModalClose} />
                </Box>
            </StyledModal>
            <div style={{display: "flex", height: "100%"}}>
                <CssBaseline />
                <AppBar title="Data4Weather" position="fixed" sx={{zIndex: (theme: any) => theme.zIndex.drawer + 1}} />
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: "border-box"},
                    }}
                >
                    <Box sx={{paddingLeft: "12px", paddingRight: "12px", paddingTop: 8}}>
                        <DatePickerRange
                            disableEndDate
                            startDate={startDate.toDate()}
                            endDate={endDate.toDate()}
                            onChange={handleChangeDateRange}
                            onNextStartDate={() => handleChangeDateRange({startDate: startDate.add(1, "days").toDate(), endDate: endDate.toDate()})}
                            onPrevStartDate={() =>
                                handleChangeDateRange({startDate: startDate.subtract(1, "days").toDate(), endDate: endDate.toDate()})
                            }
                        />
                        <div id="geocoder" style={{paddingLeft: "12px", paddingRight: "12px", paddingTop: 10}} />
                    </Box>
                    <Box>
                        <label style={{display: "flex", alignItems: "center", paddingLeft: "12px", paddingTop: "5px", justifyContent: "flex-end"}}>
                            <span style={{flex: 5, fontSize: 15, textAlign: "right"}}>Tous</span>
                            <Checkbox style={{flex: 1}} name="tous" size="small" checked={allChecked} onChange={handleAllCheckboxChange} />
                        </label>
                        {listCheckBox.map((item: AlertItemCheckBoxType, index: number) => {
                            return <AlertItemCheckbox key={`alert_item_${index}`} item={item} handleCheckboxChange={handleLayerAlert} />;
                        })}
                    </Box>
                    <Box sx={{paddingBottom: "10px", borderBottom: "solid 1px #BDBDBD"}}>
                        <FormGroup sx={{display: "flex", padding: "12px 20px 12px 0", flexDirection: "row", justifyContent: "space-between"}}>
                            <FormControlLabel
                                control={<YellowSwitch size="small" checked={isCheckedYellow} onChange={handleCheckboxYellow} />}
                                label="Jaune"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                control={<Switch color="warning" size="small" checked={isCheckedOrange} onChange={handleCheckboxOrange} />}
                                label="Orange"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                control={<RedSwitch size="small" checked={isCheckedRed} onChange={handleCheckboxRed} />}
                                label="Rouge"
                                color="warning"
                                labelPlacement="start"
                            />
                        </FormGroup>
                        {/* <div className="buttonCreate">
                            <Button onClick={handleModalOpen} variant='contained' size='small' sx={{backgroundColor: '#1C5782', fontWeight: 600}}>
                                Créer une alert
                            </Button>
                        </div> */}
                    </Box>
                    <AlertAccordionList alertItemList={alert} />
                </Drawer>
            </div>
            <div className="map-container" id="mapBox" />
        </div>
    );
}

import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import Fuse from "fuse.js";

export default function ActivitiesFilterSearch({
  activitiesOptions,
  onChange,
  disabled,
}: any) {
  const fuseOptions = {
    includeScore: true,
    keys: ["label"],
  };

  const [fuse, setFuse] = useState<any>(new Fuse([], fuseOptions));

  useEffect(() => {
    if (!activitiesOptions) {
      return;
    }
    const activities = activitiesOptions.map((activity: any) => ({
      value: activity.code,
      label: activity.label,
    }));
    setFuse(new Fuse(activities, fuseOptions));
  }, [activitiesOptions]);

  const handleChange = (values: any) => {
    onChange && onChange(values.map((value: any) => value.item.value));
  };

  const promiseOptions = (inputValue: string): any =>
    new Promise((resolve, reject) => {
      const result = fuse.search(inputValue);
      resolve(result);
    });

  return (
    <div className="NafCodeFilterComponent">
      <AsyncSelect
        isDisabled={disabled}
        placeholder="Filtrer par activité..."
        isMulti
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        onChange={(value: any) => handleChange(value)}
        getOptionValue={(option: any) => option.item.value}
        getOptionLabel={(option: any) => option.item.label}
      />
    </div>
  );
}

import React, { useEffect } from "react";

export interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
    children?: React.ReactNode;
    data4HomeInfo?: any
}

const Map: React.FC<MapProps> = ({
    onClick,
    onIdle,
    children,
    style,
    data4HomeInfo,
    ...options
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useEffect(() => {
        let marker: any
        if (map) {
            map.setOptions(options);
        }
        if (options.center) {
            marker = new google.maps.Marker({
            position: options.center,
            map
            })
        }
        return () => {
            marker.setMap(null)
        }
    }, [map, options]);

    useEffect(() => {
        let buildingData: any, parcellData: any, bdnbData: any, volumes: any
        if (map && data4HomeInfo) {
            // if (data4HomeInfo.empriseBatieParis && data4HomeInfo.empriseBatieParis.features && data4HomeInfo.empriseBatieParis.features.length > 0) {
            //     buildingData = new google.maps.Data()
            //     buildingData.addGeoJson(data4HomeInfo.empriseBatieParis)
            //     buildingData.setStyle({
            //         fillColor: "#770000",
            //         strokeColor: "#FF0000"
            //     })
            //     buildingData.setMap(map)
            // }

            if (data4HomeInfo.parcels && data4HomeInfo.parcels.features && data4HomeInfo.parcels.features.length > 0) {
                parcellData = new google.maps.Data()
                parcellData.addGeoJson(data4HomeInfo.parcels)
                parcellData.setStyle({
                    fillColor: "#007700",
                    strokeColor: "#00FF00"
                })
                parcellData.setMap(map)
            }

            if (data4HomeInfo.buildings && data4HomeInfo.buildings.features && data4HomeInfo.buildings.features.length > 0) {
                bdnbData = new google.maps.Data()
                bdnbData.addGeoJson(data4HomeInfo.buildings)
                bdnbData.setStyle({
                    fillColor: "#000077",
                    strokeColor: "#0000FF"
                })
                bdnbData.setMap(map)
            }

            return () => {
                buildingData && buildingData.setMap(null)
                parcellData && parcellData.setMap(null)
                bdnbData && bdnbData.setMap(null)
                volumes && volumes.setMap(null)
            }
        }
    }, [map, data4HomeInfo]);

    React.useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
            window.google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
            map.addListener("click", onClick);
            }

            if (onIdle) {
            map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    return (
        <>
            <div ref={ref} style={style} />
            {/* {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                // set the map prop on the child component
                return React.cloneElement(child, { map });
            }
            })} */}
        </>
    );
};

export default Map
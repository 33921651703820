import { useState } from 'react';
import { InputPicker } from 'rsuite';
import './index.css'
import "rsuite/dist/rsuite.css";
import { Typography, styled } from '@mui/material';
import { modelIconAlert, typeALertEditor } from '../../constants';
import Icon from '../Icon';
import AlertInput from '../AlertInput';
// import MapInput from '../components/MapInput';

interface AlertEditorProps {
  alert ?: any
  onClose : () => void
}

export default function AlertEditor ({ alert, onClose } : AlertEditorProps) {
    const [type, setType] = useState('');

    const parseItemIcon = (type : any) => {
        return modelIconAlert.find(item => item.type === type)
    }

    if (alert) {
        return (
        <div className='AlertInput'>
            <div className='MapInput'>
                <AlertInput alert={alert} type={alert.type} onClose={onClose}/>
            </div>
        </div>
        );
    } else {
        return (
        <div className='AlertEditor' >
            <div className='MapEditor' >
            <div style={{ height: '100%', width: '100%' }}>
            <InputPicker
                data={typeALertEditor}
                placeholder="Select Alert"
                onChange={(value : string) => setType(value)}
                style={{ width: '100%'}}
                menuStyle={{zIndex : 1350}}
                size='lg'
                renderMenuItem={(label, item) => {
                    const type = parseItemIcon(item.value)?.iconType;
                    return (
                        <div style={{display:'flex', alignItems: 'center'}}>                      
                            {type&&<Icon type={`${type}_alert`} iconStyle={{height: '20px', width: '20px'}}/>}
                            <Typography style={{paddingLeft: '10px'}}>{label}</Typography>
                        </div>
                    );
                }}
                renderValue={(value, item, selectedElement) => {
                    const type = parseItemIcon(value)?.iconType;
                    return (
                        <div style={{display:'flex', alignItems: 'center'}}>                      
                            {type&&<Icon type={`${type}_alert`} iconStyle={{height: '20px', width: '20px'}}/>}
                            <Typography style={{paddingLeft: '10px', color: '#575757'}}>{selectedElement}</Typography>
                        </div>
                    );
                }}
            />
                {!type ? (
                    <div>
                        <AlertInput type={'SIMPLE'} onClose={onClose}/>
                    </div>
                ) : (
                    <div>
                        <AlertInput type={type} onClose={onClose}/>
                    </div>
                )}
            </div>
            </div>
        </div>
        );
    }
};
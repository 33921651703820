import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import "./Climate.css";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchClimateRiskAction,
  selectClimateRisk,
} from "../risk/climateRiskSlice";
import { selectAddress } from "../address/addressSlice";
import { ResultBlockType, ClimateRiskType } from "../../types";
import { climateRiskItems, scaleLevel } from "../../constants";

import SearchResultBlock from "../../components/Block";
import ClimateItem from "../../components/ClimateItem";
import RisksKey from "../../components/RiskKey/index";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const ToggleButtonWhite = styled(ToggleButton)({
  width: "70px",
  color: "white",
  fontSize: "16px",
  borderColor: "#6b9dc6",
  "&.Mui-selected:hover": {
    backgroundColor: "#6b9dc6",
  },
  "&.Mui-selected": {
    color: "white",
    backgroundColor: "#6b9dc6",
  },
});

export default function ClimateRiskResultBlock({
  id,
  iconType,
  title,
}: ResultBlockType) {
  const dispatch = useAppDispatch();
  const address = useAppSelector(selectAddress);
  const climatRisk = useAppSelector(selectClimateRisk);
  const { enqueueSnackbar } = useSnackbar();
  const [catnatList, setCatnatList] = useState<any[]>([]);
  const [rcpSelection, setRCPSelection] = useState<string>("rcp26");
  const { t } = useTranslation();

  useEffect(() => {
    if (!address?.value) {
      return;
    }
    dispatch(fetchClimateRiskAction(address?.value?.placeId));
  }, [address?.value, dispatch]);

  useEffect(
    function () {
      if (climatRisk.error) {
        enqueueSnackbar("L'interrogation des risques climatiques a échoué", {
          variant: "error",
        });
      }
      setCatnatList(climatRisk?.value?.risks?.catnats);
    },
    [climatRisk, enqueueSnackbar]
  );

  const renderClimateItems = () => {
    return climateRiskItems.map((item: ClimateRiskType, index) => (
      <Grid
        item
        sm={6}
        lg={4}
        style={{ display: "flex", justifyContent: "center" }}
        key={`climate_risk_item_${index}`}
      >
        <ClimateItem
          type={item.id}
          catnatTypes={item.catnatTypes}
          title={t(item.label)}
          content={
            climatRisk.value &&
            climatRisk.value.risks[item.id] &&
            climatRisk.value.risks[item.id].properties
          }
          warning={catnatList}
          isValue={climatRisk.status === "idle"}
          rcp={rcpSelection}
        />
      </Grid>
    ));
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newRCPSelection: string
  ) => {
    if (newRCPSelection !== null) {
      setRCPSelection(newRCPSelection);
    }
  };

  const renderRCPSelectionForm = () => (
    <>
      <span style={{ padding: "0 10px" }}>RCP</span>
      <ToggleButtonGroup
        color="primary"
        value={rcpSelection}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButtonWhite value="rcp26">2.6</ToggleButtonWhite>
        <ToggleButtonWhite value="rcp45">4.5</ToggleButtonWhite>
        <ToggleButtonWhite value="rcp85">8.5</ToggleButtonWhite>
      </ToggleButtonGroup>
    </>
  );

  return (
    <SearchResultBlock
      className="ClimateSearchBlock"
      title={title}
      iconType={iconType}
      id={id}
      rightComponent={renderRCPSelectionForm}
    >
      <Grid container className="ClimateItemsWrapper">
        {renderClimateItems()}
      </Grid>
      <RisksKey scaleLevel={scaleLevel} />
    </SearchResultBlock>
  );
}

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Modal, Theme, Typography } from '@mui/material';
import _ from 'lodash';
import Icon from '../../components/Icon';
import { SORTED_SIMPLEPROPS, modelIconAlert } from '../../constants';
import React ,{ SyntheticEvent, useState } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import AlertEditor from '../../components/AlertEditor';

export interface AlertAccordionItemProps {
    item : any,
    expanded : boolean,
    onExpand : (event: SyntheticEvent<Element, Event>, expanded: boolean) => void
}

const ExpansionPanel = withStyles({
    root: {
      '&$expanded': {
        margin: 0
      }
    },
    expanded: {}
  })(Accordion)

const ExpansionPanelSummary = withStyles({
  root: {
    minHeight: 48,
    '&$expanded': {
      minHeight: 48
    }
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(AccordionSummary)

const ExpansionPanelDetails = withStyles({
  root: {
    flexDirection: 'column'
  }
})(AccordionDetails)

const style = (theme: Theme) => ({
  width: 600,
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  backgroundColor: 'white',
  boxShadow: '0px 2px 24px #383838'
});

function returnColor (level : string) : string | undefined // return div colored to display different level of alert on popup
{
    if (level === 'JAUNE' || level === "Dégradé") {return 'yellow';}
    if (level === 'ORANGE' || level === "Mauvais") {return 'orange';}
    if (level === 'ROUGE' || level === "Très mauvais" || level === "Extrêmement mauvais") {return 'red';}
    if (level === 'INCONNU' || !level) {return 'grey';}
}

export default function AlertAccordionItem ({item, expanded, onExpand}: AlertAccordionItemProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const type = _.get(item, 'type')
  const postalCode = _.get(item, 'postal_code')
  const parseItemIcon = (type : string) => {
      return modelIconAlert.find(item => item.type === type)
  }

  const itemInfo : { [key: string]: string } = _.omit(item, ['_id', 'created_at', 'temperatures', 'type', 'postal_code'])
  const commonInfo : { [key: string]: string } = _.reduce(SORTED_SIMPLEPROPS, (acc : { [key: string]: string }, keyValue : string) => {
    if (!acc.hasOwnProperty(keyValue))
      acc[keyValue] = _.get(itemInfo, keyValue)
    return acc
  }, {})
  const extraInfo = _.filter(Object.keys(itemInfo), key => !SORTED_SIMPLEPROPS.includes(key))
  const finalInfo  : { [key: string]: string }= _.reduce(extraInfo, (acc : { [key: string]: string }, keyValue : string) => {
    if (!acc.hasOwnProperty(keyValue))
      acc[keyValue] = _.get(itemInfo, keyValue)
    return acc
  }, commonInfo)
  const renderInfo = (value : any, key : string) => {
    switch(key) {
      case 'polluants' :
        return null
      case 'expected_at':
        return `à : ${moment.utc(value).local().format('YYYY/MM/DD HH:00').toString()}`
      case 'estimated_end':
        return `fin : ${moment.utc(value).local().format('YYYY/MM/DD HH:00').toString()}`
      case 'is_new':
        return `est une nouvelle alerte : ${value}`
      case 'level_changed':
        return ` niveau modifié : ${value}`
      case 'risk_duration':
        return `durée du risque : ${value} h`
      case 'level':
        return (
          <React.Fragment>
            {'niveau :'}
            <span style={{ display: 'inline-block', height : 10, width : 50, backgroundColor : returnColor(value), alignSelf: 'center', marginLeft : 10, borderRadius : '14px'}}></span>
          </React.Fragment>
        )
      case 'previous_level':
        return (
          <React.Fragment>
            {'niveau précédent :'}
            <span style={{display: 'inline-block', height : 10, width : 50, backgroundColor : returnColor(value), alignSelf: 'center', marginLeft : 10, borderRadius : '14px'}}></span>
          </React.Fragment>
        )
      case 'rain':
        return `${key} : ${Math.round(value)} mm`
      case 'postal_code':
        return `code postal : ${value}`
      case 'temperatures_max_heatwave':
      case 'temperatures_max_coldfront':
        return `température maximale : ${parseFloat(value).toFixed(1)} °C`
      case 'temperatures_min_coldfront':
      case 'temperatures_min_heatwave':
        return `température minimale : ${parseFloat(value).toFixed(1)} °C`
      case 'snow_precipitation_high':
        return `snow precipitation high : ${parseFloat(value).toFixed(1)} cm`
      case 'snow_precipitation_low':
        return `snow precipitation low : ${parseFloat(value).toFixed(1)} cm`
      case 'frost_temperature':
      case 'frost_temperature_dew':
        return `${key} : ${parseFloat(value).toFixed(1)} °C`
      case 'high_wind_average':
        return `high wind average : ${parseFloat(value).toFixed(1)} km/h`
      case 'high_wind_gust':
        return `high wind gust : ${parseFloat(value).toFixed(1)} km/h`
      case 'run_date':
        return `run date : ${moment.utc(value).local().format('YYYY/MM/DD HH:00').toString()}`
      default:
        return `${key} : ${value}`
    }
  }

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      >
        <Box sx={style}>
            <AlertEditor alert={item} onClose={handleModalClose}/>
        </Box>
      </Modal>
      <ExpansionPanel disableGutters expanded={expanded} onChange={onExpand}>
        <ExpansionPanelSummary>
          <div>
            <Typography variant="h6">{parseItemIcon(type)?.title}</Typography>
            <Typography variant="subtitle2">{postalCode}</Typography>
          </div>
          <Icon type={`${parseItemIcon(type)?.iconType}_alert`} iconStyle={{height:'30px', width: '30px'}}/>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {_.map(finalInfo, (value, key) => (
            <Typography key={key} gutterBottom>{renderInfo(value, key)}</Typography>
          ))}
          {/* <Button onClick={handleModalOpen} variant='contained' size='small' sx={{backgroundColor: '#1C5782', fontWeight: 600}}>
            Modifier l'alerte
          </Button> */}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>

  );
}

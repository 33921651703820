import { call, put, takeLatest } from "redux-saga/effects";
import {
  createNewPerilAction,
  deletePerilAction,
  fetchPerilAction,
  updatePerilAction,
} from "./arretPerilSlice";
import {
  createPeril,
  deletePerilById,
  getPerilForCoordinate,
  updatePerilById,
} from "../address/data4homeAPI";

function* fetchPeril(action: any): any {
  try {
    const { lat, lng } = action.payload;
    yield put(fetchPerilAction.pending());
    const perils: any = yield call(getPerilForCoordinate, { lat, lng });
    yield put(fetchPerilAction.fulfilled(perils));
  } catch (exception: any) {
    yield put(fetchPerilAction.rejected(exception.message));
  }
}

function* createNewPeril(action: any): any {
  try {
    const { id, newPeril } = action.payload;
    yield put(createNewPerilAction.pending());
    const peril: any = yield call(createPeril, {
      newPeril,
    });
    yield put(createNewPerilAction.fulfilled(peril));
  } catch (exception: any) {
    yield put(createNewPerilAction.rejected(exception.message));
  }
}

function* updatePeril(action: any): any {
  try {
    const { id, newPeril } = action.payload;
    yield put(updatePerilAction.pending());
    const peril: any = yield call(updatePerilById, {
      id,
      newPeril,
    });
    yield put(updatePerilAction.fulfilled(peril));
  } catch (exception: any) {
    yield put(updatePerilAction.rejected(exception.message));
  }
}

function* deletePeril(action: any): any {
  try {
    const { id } = action.payload;
    yield put(deletePerilAction.pending());
    const res: any = yield call(deletePerilById, { id });
    yield put(deletePerilAction.fulfilled(res));
  } catch (exception: any) {
    yield put(deletePerilAction.rejected(exception.message));
  }
}

function* arretPerilSaga() {
  yield takeLatest(fetchPerilAction, fetchPeril);
  yield takeLatest(createNewPerilAction, createNewPeril);
  yield takeLatest(updatePerilAction, updatePeril);
  yield takeLatest(deletePerilAction, deletePeril);
}

export default arretPerilSaga;

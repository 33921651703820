import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import { AppTheme } from "../..";
import { IconButton, Menu, MenuItem } from "@mui/material";
import Icon from "../../components/Icon";
import AuthService from "../auth/authService";

export default function DenseAppBar(props: any) {
  const { title } = props;
  const navigate = useNavigate();
  const theme = useTheme<AppTheme>();
  const logout = React.useCallback(function () {
    AuthService.logout();
    navigate("/auth/signin");
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleNavigate = (url: string): void => {
    navigate(url);
    setAnchorEl(null);
  };
  return (
    <Box sx={{}}>
      <AppBar component="nav" {...props}>
        <Toolbar
          variant="dense"
          sx={{
            backgroundColor: theme.backgroundColor.secondary,
            color: theme.color.fontTitle,
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 0",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={handleMenuClick}
            >
              <Icon type="menu" iconStyle={{ height: "17px", width: "17px" }} />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              {`Vision - ${title}`}
            </Typography>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 450,
                  fontSize: "20px",
                  padding: "6px 16px",
                  color: theme.color.fontTitle,
                }}
              >
                Switch to
              </Typography>
              <MenuItem
                sx={{ width: "320px", color: theme.color.fontTitle }}
                onClick={() => handleNavigate("/")}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon
                    type="data4home"
                    iconStyle={{ height: "35px", width: "35px" }}
                  />
                  <Typography variant="subtitle1" sx={{ paddingLeft: "16px" }}>
                    Data4Home
                  </Typography>
                </div>
              </MenuItem>
              <MenuItem
                sx={{ width: "320px", color: theme.color.fontTitle }}
                onClick={() => handleNavigate("/map")}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon
                    type="data4weather"
                    iconStyle={{ height: "35px", width: "35px" }}
                  />
                  <Typography variant="subtitle1" sx={{ paddingLeft: "16px" }}>
                    Data4Weather
                  </Typography>
                </div>
              </MenuItem>
            </Menu>
          </Box>
          <img
            src="data4risk-logo.png"
            alt="data4risk logo"
            style={{ height: "35px" }}
          />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {/* <Button key="account" sx={{ color: '#fff' }} onClick={() => window.open("https://idp.dev.data4home.data4risk.com/realms/Data4Home/account/", '_blank')}>
                    Compte
                </Button> */}
            <Button
              key="logout"
              sx={{ color: theme.color.fontTitle }}
              onClick={() => logout()}
            >
              Déconnexion
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

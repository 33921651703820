import _ from "lodash";
import "./index.css";

import Icon from "../Icon";
import StepProgressBar from "../StepProgressBar";

import { scaleLevel } from "../../constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../app/hooks";
import { selectClimateRisk } from "../../features/risk/climateRiskSlice";
interface PropsType {
  type: string;
  catnatTypes: string[];
  title: string;
  content: any | undefined;
  warning: any[] | undefined;
  isValue: boolean;
  rcp: string;
}

const containerIconStyle: Record<string, string> = {
  justifyContent: "center",
  alignItems: "center",
  width: "5rem",
  height: "5rem",
  borderRadius: "50%",
  boxShadow: "0px 4px 16px 0px rgba(189, 189, 189, 1)",
  cursor: "pointer",
};

export default function ClimateItem({
  type,
  catnatTypes,
  title,
  content,
  warning,
  isValue,
  rcp,
}: PropsType): JSX.Element {
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [isEvent, setIsEvent] = useState<boolean>(false); // setisEvent will be put later when correctly used
  const [is2FireRisk, setIs2FireRisk] = useState<boolean>(false);
  const { t } = useTranslation();
  const DataRisk = useAppSelector(selectClimateRisk)?.value?.risks;

  useEffect(() => {
    setIs2FireRisk(
      !!content?.originalFireRisk &&
        content?.fireRisk !== content?.originalFireRisk
    );
  }, [content]);

  useEffect(() => {
    let alert: boolean = false;
    let event: boolean = false;
    if (warning) {
      for (let i = 0; i < warning?.length; i++) {
        if (
          catnatTypes.filter((value) => warning[i].type.includes(value)).length
        ) {
          alert = true;
          break;
        }
      }
    }
    if (DataRisk?.events.length !== 0 && type === "coastalErosion")
      event = true;
    setIsWarning(alert);
    setIsEvent(event);
  }, [warning, content]);

  const setLevelColor = () => {
    if (!content || !isValue) return scaleLevel.disabled.color;

    const scaleLevelByRisk: Record<string, () => string | null> = {
      flood: () => {
        // return content.waterMask ? scaleLevel["very_high"].color : scaleLevel["no_risk"].color;
        const floodColor =
          _.find(scaleLevel, (value) => value.num === content.risk) ||
          scaleLevel["disabled"];
        return floodColor.color;
      },
      pluvialFlood: () => {
        // return content.waterMask ? scaleLevel["very_high"].color : scaleLevel["no_risk"].color;
        const floodColor =
          _.find(scaleLevel, (value) => value.num === content.risk) ||
          scaleLevel["disabled"];
        return floodColor.color;
      },
      drought: () => {
        const droughtColor =
          _.find(scaleLevel, (value) => value.desc === content.risk) ||
          scaleLevel["disabled"];
        return droughtColor.color;
      },
      fire: () => {
        const fireColor =
          _.find(scaleLevel, (value) => value.num === content.fireRisk) ||
          scaleLevel["disabled"];
        return fireColor.color;
      },
      hailStorm: () => {
        const hailColor =
          _.find(scaleLevel, (value) => value.num === content.hail) ||
          scaleLevel["disabled"];
        return hailColor.color;
      },
      heavyRain: () => {
        const heavyRainColor =
          _.find(scaleLevel, (value) => value.num === content[rcp]) ||
          scaleLevel["disabled"];
        return heavyRainColor.color;
      },
      wind: () => {
        const windColor =
          _.find(scaleLevel, (value) => value.num === content[rcp]) ||
          scaleLevel["disabled"];
        return windColor.color;
      },
      submersion: () => {
        // content level has value from 1 to 5, scaleLevel has value from 0 to 4
        const submersionColor =
          _.find(scaleLevel, (value) => value.num === content.level - 1) ||
          scaleLevel["disabled"];
        return submersionColor.color;
      },
      coastalErosion: () => {
        const coastalErosionColor =
          _.find(scaleLevel, (value) => value.num === content.level) ||
          scaleLevel["disabled"];
        return coastalErosionColor.color;
      },
    };

    const color = scaleLevelByRisk[type];
    return color();
  };

  const setStepLevel = () => {
    if (!content || !isValue) return scaleLevel.disabled.num;

    const stepLevelByRisk: Record<string, () => number | null> = {
      flood: () => {
        // if (!content.waterMask) {
        //     return scaleLevel.no_risk.num;
        // } else {
        //     return content.waterDepth ? getLowerFloodLevel(content.waterDepth) : null;
        // }
        const floodLevel =
          _.find(scaleLevel, (value) => value.num === content.risk) ||
          scaleLevel["disabled"];
        return floodLevel.num;
      },
      pluvialFlood: () => {
        // if (!content.waterMask) {
        //     return scaleLevel.no_risk.num;
        // } else {
        //     return content.waterDepth ? getLowerFloodLevel(content.waterDepth) : null;
        // }
        const floodLevel =
          _.find(scaleLevel, (value) => value.num === content.risk) ||
          scaleLevel["disabled"];
        return floodLevel.num;
      },
      drought: () => {
        const stepDroughtLevel = _.find(
          scaleLevel,
          (value) => value.desc === content.risk
        );
        return stepDroughtLevel.num;
      },
      fire: () => {
        return content.fireRisk;
      },
      hailStorm: () => {
        return content.hail;
      },
      heavyRain: () => {
        return content[rcp];
      },
      wind: () => {
        return content[rcp];
      },
      submersion: () => {
        // content level has value from 1 to 5, scaleLevel has value from 0 to 4
        return content.level - 1;
      },
      coastalErosion: () => {
        // content level has value from 1 to 5, scaleLevel has value from 0 to 4
        return content.level;
      },
    };
    const stepLevel = stepLevelByRisk[type];
    return stepLevel();
  };

  const setRiskLabel = () => {
    if (!content || !isValue) return scaleLevel.disabled.label;

    const labelByRisk: Record<string, () => string> = {
      flood: () => {
        // const floodLevelNum = getLowerFloodLevel(content.waterDepth);
        // const floodLevel = _.find(scaleLevel, (value) => floodLevelNum === value.num) || scaleLevel["disabled"];
        // return floodLevel.label;
        const floodLevel =
          _.find(scaleLevel, (value) => value.num === content.risk) ||
          scaleLevel["disabled"];
        return floodLevel.label;
      },
      pluvialFlood: () => {
        // const floodLevelNum = getLowerFloodLevel(content.waterDepth);
        // const floodLevel = _.find(scaleLevel, (value) => floodLevelNum === value.num) || scaleLevel["disabled"];
        // return floodLevel.label;
        const floodLevel =
          _.find(scaleLevel, (value) => value.num === content.risk) ||
          scaleLevel["disabled"];
        return floodLevel.label;
      },
      drought: () => {
        const stepDroughtLevel =
          _.find(scaleLevel, (value) => value.desc === content.risk) ||
          scaleLevel["disabled"];
        return stepDroughtLevel.label;
      },
      fire: () => {
        const fireLevel =
          _.find(scaleLevel, (value) => value.num === content.fireRisk) ||
          scaleLevel["disabled"];
        return fireLevel.label;
      },
      hailStorm: () => {
        const hailLevel =
          _.find(scaleLevel, (value) => value.num === content.hail) ||
          scaleLevel["disabled"];
        return hailLevel.label;
      },
      heavyRain: () => {
        const heavyRainLevel =
          _.find(scaleLevel, (value) => value.num === content[rcp]) ||
          scaleLevel["disabled"];
        return heavyRainLevel.label;
      },
      wind: () => {
        const windLevel =
          _.find(scaleLevel, (value) => value.num === content[rcp]) ||
          scaleLevel["disabled"];
        return windLevel.label;
      },
      submersion: () => {
        const submersionLevel =
          _.find(scaleLevel, (value) => value.num === content.level - 1) ||
          scaleLevel["disabled"];
        return submersionLevel.label;
      },
      coastalErosion: () => {
        const coastalErosionLevel =
          _.find(scaleLevel, (value) => value.num === content.level) ||
          scaleLevel["disabled"];
        return coastalErosionLevel.label;
      },
    };
    const levelLabel = labelByRisk[type];
    return levelLabel();
  };

  // const getLowerFloodLevel = (value: number): number | null => {
  //     let lastNum = null;
  //     for (const key in scaleLevel) {
  //         if (scaleLevel[key].flood_level <= value) {
  //             lastNum = scaleLevel[key].num;
  //         }
  //     }
  //     return lastNum;
  // };

  const setTooltipInfo = (step: number | null) => {
    if (step === null) {
      return "";
    }
    const level = _.find(scaleLevel, (value) => value.num === step);
    return level ? level.info[type] : null;
  };

  return (
    <div className="ClimateItemWrapper">
      <div className="RiskAlert">
        {/* {is2FireRisk&&(
                     <Icon type={`risque_lv${content?.originalFireRisk}`} containerStyle={{marginTop: '40px'}} iconStyle={{width: '30px', height:'30px'}}/>
                )} */}
        <div className="RiskBarIcon">
          {content || content === null ? (
            <div className="RiskLabel" style={{ color: setLevelColor() }}>
              {t(setRiskLabel())}
            </div>
          ) : (
            // blank line when we dont have this climate risk
            <div className="RiskLabel">
              <br />
            </div>
          )}
          <StepProgressBar step={setStepLevel()} color={setLevelColor()} />
          <Icon
            type={`${type}_svg`}
            size="3rem"
            iconStyle={{ backgroundColor: setLevelColor() }}
            containerStyle={{
              ...containerIconStyle,
              backgroundColor: setLevelColor(),
            }}
          />
          <h5 style={{ textAlign: "center" }}>{title}</h5>
        </div>
        {(!!isWarning || !!isEvent) && (
          <div className="Warning">
            <Icon type="warning" />
            {!!isWarning && (
              <div className="WarningText">
                <p>{t("warning.catNat")}</p>
              </div>
            )}
            {!!isEvent && (
              <div className="WarningText">
                <p>{t("warning.mandates")}</p>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="Infos">
        <ul className={!isWarning ? "RiskInfos" : "InfosWarning"}>
          {type === "flood" && content && content.waterDepth && (
            <li>Hauteur de l'eau maximale : {content.waterDepth} m</li>
          )}
          {type === "flood" && content && content.estimatedDamage > 0 && (
            <li>Coût des dommages : {content.estimatedDamage} €/m2</li>
          )}
          {content?.originalFireRisk && is2FireRisk ? (
            <li className="textOriginalRisk">{t(setTooltipInfo(5))}</li>
          ) : (
            <li>{t(setTooltipInfo(setStepLevel()))}</li>
          )}
        </ul>
      </div>
    </div>
  );
}

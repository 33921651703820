import { useState, useEffect, ChangeEvent } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import _ from "lodash";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  fetchAddressAction,
  selectAddress,
  selectAutoComplete,
  setAutoCompleteValue,
} from "../address/addressSlice";
import {
  selectSolarPanelModellData,
  selectPoolModelData,
  selectRoofModelData,
  selectGraffitiModelData,
  selectWalledWindowModelData,
  selectBuildingAgeModelData,
  selectAIModelSelection,
  setAiModelSelection,
} from "../address/aiDetectionSlice";

import { useSnackbar } from "notistack";

import { arrayMove } from "@dnd-kit/sortable";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Checkbox from "@mui/material/Checkbox";
import AppBar from "../address/AppBar";

import PlacesAutocomplete from "./PlacesAutocomplete";
import SearchBlocksWrapper from "./SearchBlocksWrapper";

import Icon from "../../components/Icon";
import NavigationMenu from "../../components/NavigationMenu";

import { BlockItemType } from "../../types";
import { blockItems } from "../../constants";
import { getLocalStorageItem, setLocalStorageItem } from "../../app/functions";

import "../address/Address.css";
import "./Search.css";
import ButtonConfirm from "../../components/ButtonConfirm/ButtonConfirm";
import { generatePdf } from "../address/data4homeAPI";

const drawerWidth = 350;

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

const API_KEY = "AIzaSyDq2gssPa5aiQDHXNsQU7fP9DZd-v5pzho";

export default function ClippedDrawer() {
  const dispatch = useAppDispatch();
  const aiDetectionSelectionState = useAppSelector(selectAIModelSelection);

  const initialBlockItemList = getLocalStorageItem(
    "block-result-list",
    blockItems
  );

  /* google place search management */
  const googleAutocomplete = useAppSelector(selectAutoComplete);
  const [blockItemList, setBlockItemList] =
    useState<BlockItemType[]>(initialBlockItemList);

  const handleSetBlockItemList = (activeId: string, overId: string) => {
    setBlockItemList((items) => {
      const activeIndex = _.findIndex(
        items,
        (item: BlockItemType) => activeId === item.id
      );
      const overIndex = _.findIndex(
        items,
        (item: BlockItemType) => overId === item.id
      );

      const newBlockItemList = arrayMove(items, activeIndex, overIndex);

      setLocalStorageItem(
        "block-result-list",
        JSON.stringify(newBlockItemList)
      );
      return newBlockItemList;
    });
  };

  const [isExportDisable, setIsExportDisable] = useState(true);
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);

  useEffect(() => {
    (async () => {
      if (!googleAutocomplete) {
        return;
      }
      dispatch(fetchAddressAction(googleAutocomplete.value.place_id));
    })();
  }, [googleAutocomplete, dispatch]);

  const handleChange = (place: any) => {
    dispatch(setAutoCompleteValue(place));
  };

  /* end of google place search management */

  const address = useAppSelector(selectAddress);

  const solarPanel = useAppSelector(selectSolarPanelModellData),
    pool = useAppSelector(selectPoolModelData),
    roof = useAppSelector(selectRoofModelData),
    graffiti = useAppSelector(selectGraffitiModelData),
    walledWindow = useAppSelector(selectWalledWindowModelData),
    buildingAge = useAppSelector(selectBuildingAgeModelData);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setAiModelSelection(event.target.name, event.target.checked));
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(
    function () {
      if (solarPanel.error) {
        enqueueSnackbar(
          "L'interrogation du modèle de détection de panneaux solaires à échoué",
          { variant: "error" }
        );
      }
    },
    [solarPanel, enqueueSnackbar]
  );

  useEffect(
    function () {
      if (graffiti.error) {
        enqueueSnackbar(
          "L'interrogation du modèle de détection de graffitis à échoué",
          { variant: "error" }
        );
      }
    },
    [graffiti, enqueueSnackbar]
  );

  useEffect(
    function () {
      if (walledWindow.error) {
        enqueueSnackbar(
          "L'interrogation du modèle de détection de graffitis à échoué",
          { variant: "error" }
        );
      }
    },
    [walledWindow, enqueueSnackbar]
  );

  useEffect(
    function () {
      if (pool.error) {
        enqueueSnackbar(
          "L'interrogation du modèle de détection de piscines à échoué",
          { variant: "error" }
        );
      }
    },
    [pool, enqueueSnackbar]
  );

  useEffect(
    function () {
      if (roof.error) {
        enqueueSnackbar(
          "L'interrogation du modèle de détection de toîts à échoué",
          { variant: "error" }
        );
      }
    },
    [roof, enqueueSnackbar]
  );

  useEffect(
    function () {
      if (buildingAge.error) {
        enqueueSnackbar(
          "L'interrogation du modèle d'estimation de l'âge du batîment à échoué",
          { variant: "error" }
        );
      }
    },
    [buildingAge, enqueueSnackbar]
  );

  useEffect(
    function () {
      if (address.error) {
        enqueueSnackbar(
          "L'interrogation du serveur de recherche d'adresse à échoué",
          { variant: "error" }
        );
      }
      if (address.value) {
        setIsExportDisable(false);
      }
    },
    [address, enqueueSnackbar]
  );

  const handleExportPdf = async () => {
    try {
      setIsLoadingPDF(true);
      const response = await generatePdf(address.value.placeId);
      if (response.status !== 200) {
        enqueueSnackbar("Erreur lors de l'exportation du PDF", {
          variant: "error",
          autoHideDuration: 1000,
        });
      }

      const filePDF = new Blob([response.arraybuffer], {
        type: "application/pdf",
      });
      const fileURLPdf = URL.createObjectURL(filePDF);
      window.open(fileURLPdf, "_blank");
      setIsLoadingPDF(false);
    } catch (error) {
      setIsLoadingPDF(false);
      enqueueSnackbar("Erreur lors de l'exportation du PDF", {
        variant: "error",
        autoHideDuration: 1000,
      });
      console.error("Erreur lors de l'exportation du PDF", error);
    }
  };

  return (
    <Wrapper apiKey={API_KEY} libraries={["places"]} render={render}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          title="Data4Home"
          position="fixed"
          sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        />
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Box
            sx={{ paddingLeft: "12px", paddingRight: "12px", paddingTop: 10 }}
          >
            <PlacesAutocomplete
              initialValue={googleAutocomplete?.label}
              onChange={handleChange}
            />
          </Box>
          <Box>
            <p style={{ paddingLeft: "12px", paddingRight: "12px" }}>
              Choisissez les algos D4 Home pour lesquels vous souhaitez un
              résultat immédiat lors de la recherche
            </p>
          </Box>
          <Box>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "12px",
              }}
            >
              <Icon containerStyle={{ flex: 1 }} type="pool" />
              <span style={{ flex: 4 }}>Détection de piscine</span>
              <Checkbox
                style={{ flex: 1 }}
                name="pool"
                onChange={handleCheckboxChange}
                checked={aiDetectionSelectionState["pool"]}
                disabled={pool.status === "loading"}
              />
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "12px",
              }}
            >
              <Icon containerStyle={{ flex: 1 }} type="solarPanel" />
              <span style={{ flex: 4 }}>Détection de panneaux solaires</span>
              <Checkbox
                style={{ flex: 1 }}
                name="solarPanel"
                onChange={handleCheckboxChange}
                checked={aiDetectionSelectionState["solarPanel"]}
                disabled={solarPanel.status === "loading"}
              />
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "12px",
              }}
            >
              <Icon containerStyle={{ flex: 1 }} type="graffiti" />
              <span style={{ flex: 4 }}>Détection de graffitis</span>
              <Checkbox
                style={{ flex: 1 }}
                name="graffiti"
                onChange={handleCheckboxChange}
                checked={aiDetectionSelectionState["graffiti"]}
                disabled={graffiti.status === "loading"}
              />
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "12px",
              }}
            >
              <Icon containerStyle={{ flex: 1 }} type="window" />
              <span style={{ flex: 4 }}>Détection de fenêtre murées</span>
              <Checkbox
                style={{ flex: 1 }}
                name="walledWindow"
                onChange={handleCheckboxChange}
                checked={aiDetectionSelectionState["walledWindow"]}
                disabled={walledWindow.status === "loading"}
              />
            </label>
          </Box>

          <Box style={{ flex: 1 }}>
            <NavigationMenu
              blockItemList={blockItemList}
              handleSetBlockItemList={handleSetBlockItemList}
            />
          </Box>

          <ButtonConfirm
            title="Export PDF"
            onClick={handleExportPdf}
            disabled={isExportDisable}
            loading={isLoadingPDF}
            size="150px"
          />

          <div
            className="BuildNumber"
            style={{ padding: "10px", textAlign: "center" }}
          >
            build: {process.env.REACT_APP_BUILD_NUMBER}
          </div>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            paddingLeft: 3,
            paddingRight: 3,
            paddingTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SearchBlocksWrapper blockItemList={blockItemList} />
        </Box>
      </Box>
    </Wrapper>
  );
}
